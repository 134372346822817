<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton"
                               @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form ref="formdata"
                     :inline="true"
                     :model="formdata.saveJson"
                     class="demo-form-inline form_row"
                     size="small"
                     label-width="200px">
                <div class="titlediv">
                    <div class="leftline"></div>
                    <div class="subtitle">设备基本信息</div>
                    <div class="leftline"></div>
                </div>
                <el-form-item label="监测对象：">
                    <label>{{ formdata.saveJson.SBQD[0].JCDXMC }}</label>
                </el-form-item>
                <el-form-item label="监测位置：">
                    <label>{{ formdata.saveJson.SBQD[0].JCWZMC }}</label>
                </el-form-item>
                <el-form-item label="设备名称：">
                    <label>{{ formdata.saveJson.SBQD[0].SBMC }}</label>
                </el-form-item>
                <el-form-item label="设备类型：">
                    <label>{{ formdata.saveJson.SBQD[0].LXMC }}</label>
                </el-form-item>
                <el-form-item label="采集周期：">
                    <label>{{ formdata.saveJson.SBQD[0].CJZQ }}</label>
                </el-form-item>
                <el-form-item label="周期单位：">
                    <label>{{ formdata.saveJson.SBQD[0].CJZQDWMC }}</label>
                </el-form-item>
                <el-form-item label="安装时间：">
                    <label>{{
                        formdata.saveJson.SBQD[0].AZSJ.split(" ")[0]
                    }}</label>
                </el-form-item>

                <div class="titlediv">
                    <div class="leftline"></div>
                    <div class="subtitle">设备配置</div>
                    <div class="leftline"></div>
                </div>
                <el-form-item label="抓取周期：">
                    <el-input v-model="formdata.saveJson.CONFIG[0].GRABCYCLE"
                              :maxlength="50"
                              onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                              onkeydown="if(event.keyCode==32) return false"
                              placeholder="请输入抓取周期"
                              :disabled="!isEdit">
                    </el-input>
                </el-form-item>
                <el-form-item label="周期单位：">
                    <el-select v-model="formdata.saveJson.CONFIG[0].GRABCYCLEUNIT"
                               placeholder="请选择周期单位"
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in zqdwList"
                                   :key="item.CODE + index"
                                   :label="item.NAME"
                                   :value="item.CODE">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="负责人：">
                    <el-select v-model="formdata.saveJson.CONFIG[0].MANAGERID"
                               placeholder="请选择负责人"
                               :disabled="!isEdit">
                        <el-option v-for="(item, index) in fzrList"
                                   :key="item.Id + index"
                                   :label="item.Realname"
                                   :value="item.Id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../../edit-button.js";
import infoMixin from "../../info_mixin.js";
import { mapActions } from "vuex";
export default {
    name: "equipconfig_form",
    components: {},
    mixins: [infoMixin],
    data () {
        return {
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                    SBQD: [{}],
                    CONFIG: [
                        {
                            GRABCYCLE: "",
                            GRABCYCLEUNIT: "",
                            MANAGERID: "",
                        },
                    ],
                },
                heritageId: "",
                itemID: "117",
                userName: "",
                userId: "",
            },
            zqdwList: [],
            fzrList: [],
            zqdwValue: "", // 周期单位
            itemID: "117",
        };
    },
    computed: {},
    watch: {
        "formdata.saveJson.cnsxlylx": function () { },
    },
    mounted () {
        this.getZqdwList();
        this.getFzrList();
        // this.getFromData();
    },
    methods: {
        ...mapActions([
            "GetSbqdAndJieruConfigDetail", // 获取详情
            "GetGathererList", // 获取负责人列表
            "SaveData",
            "GetZqdwList", //获取周期单位
        ]),
        async getFromData () {
            let params = {
                itemid: "117",
                ID: this.$route.query.id,
            };
            let result = await this.GetSbqdAndJieruConfigDetail(params);
            if (result) {
                Object.assign(this.formdata.saveJson, result);
            }
        },
        // 获取周期单位列表
        async getZqdwList () {
            this.zqdwList = [];
            let result = await this.GetZqdwList();
            if (result && result.length > 0) {
                this.zqdwList = result;
            }
        },
        //负责人列表获取
        async getFzrList () {
            this.fzrList = [];
            let result = await this.GetGathererList();
            if (result && result.length > 0) {
                this.fzrList = result;
            }
        },
        async saveData () {
            let data = this.formdata.saveJson.CONFIG[0];
            let res = await this.SaveData({
                data: data,
                itemid: "117",
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.returnList();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.titlediv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 25px;
}
.leftline {
    width: 180px;
    // width: calc(50% - 108px);
    height: 0px;
    border-bottom: 1px solid #ccc;
}
.subtitle {
    width: 155px;
    height: 30px;
    background-color: #1862ad;
    border-radius: 15px;
    color: #fff;
    line-height: 30px;
    margin: 0 30px;
    text-align: center;
}
</style>
