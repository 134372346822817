const snButton = {
    buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'saveNosubmit',
            name: '保存',
            round: true,
            backColor: '#28cdd9',
            color: '#fff'
        }
        // , {
        //     isShow: true,
        //     btnType: 'button',
        //     operateType: 'saveSubmit',
        //     name: '保存并提交',
        //     round: true,
        //     backColor: '#6f32ff',
        //     color: '#fff'
        // }
        , {
            isShow: true,
            btnType: 'button',
            operateType: 'returnList',
            name: '返回',
            round: true,
            backColor: '#ff9066',
            color: '#fff'
        }
    ]
};
export default snButton;